<script lang="ts" setup>
	import type { IVideo } from "~/server/models/video";

	const props = withDefaults(
		defineProps<{
			value: IVideo;
			autoplay?: boolean;
			badge: boolean;
		}>(),
		{
			autoplay: true,
			badge: true,
		}
	);

	const playing = ref(props.autoplay);
</script>
<template>
	<div class="video-player">
		<template v-if="value.mux?.state === 'ready'">
			<!-- <spinner size="large" color="white" /> -->
			<MuxPoster v-if="!playing" :mux="value.mux" />
			<MuxPlayer v-if="playing" :mux="value.mux" />
		</template>

		<template v-else-if="value.cloudflare?.state === 'ready'">
			<!-- <spinner size="large" color="white" /> -->
			<CloudflarePoster v-if="!playing" :cloudflare="value.cloudflare" />
			<CloudflarePlayer v-if="playing" :cloudflare="value.cloudflare" />
		</template>

		<template v-else-if="value.r2?.filename">
			<R2Poster v-if="!playing && value.r2.thumbnail" :r2="value.r2" />
			<R2Player v-if="playing" :r2="value.r2" />
		</template>

		<div class="badge" v-if="badge">
			<p class="tiny" v-if="value.mux?.state === 'ready'">MX</p>
			<p class="tiny" v-else-if="value.cloudflare?.state === 'ready'">CF</p>
			<p class="tiny" v-else-if="value.r2?.filename">R2</p>
		</div>
	</div>
</template>

<style scoped lang="scss">
	.video-player {
		flex-shrink: 0;
		position: relative;
		display: flex;
		background: var(--beige-light);
		overflow: hidden;
		border-radius: 6px;

		img,
		video {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center center;
			z-index: 5;
		}

		.spinner {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}

		.badge {
			position: absolute;
			bottom: 3px;
			right: 3px;
			height: 30px;
			width: 30px;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			z-index: 10;
			background: rgba(0, 0, 0, 0.25);
			pointer-events: none;
			p {
				color: white;
				font-weight: 600;
				font-size: 10px;
			}
		}
	}
</style>
